import request from '@/utils/request';

/* 获取首页数据 */
export function getHomeListApi () {
  return request({
    url: '/node/index'
  })
}
/* 咨询列表 */
export function getArticleApi (params = {}) {
  return request({
    url: '/node/news-list',
    params
  })
}
/* 咨询详情 */
export function getArticleConApi (id) {
  return request({
    url: `/node/news-list/${id}`,
  })
}

/* 获取客户案例 */
export function getClient () {
  return request({
    url: '/node/index'
  })
}


// /* 登录接口 */
// export function loginAppApi (params = {}) {
//   return request({
//     url: '/node/users/app/login/meihui',
//     method: 'POST',
//     params
//   })
// }

// /* 发送手机验证码 */
// export function postTelCodeApi (tel, messageSign = '全球蛙') {
//   return request({
//     url: `/node/users/app/customers/send/${tel}/2`,
//     params: { messageSign }
//   })
// }
// /* 20200130新版发送手机验证码 */
// export function postTelNewCodeApi (params) {
//   return request({
//     url: `/node/users/app/customers/send-sms`,
//     method: 'POST',
//     params
//   })
// }

// export function getAppUrlApi(channelNo) {
//   return request({
//     url:  `/node/merchants/app/version/${channelNo}`
//   });
// }
