// 导入vue
import Vue from "vue";

// 导入 APP 根组件
import app from "./App.vue";
import "./utils/rem";
import "./utils/reset.less";
// 导入 vant UI 组件 1
import {
  Popup,
  SwipeItem,
  Lazyload,
  Grid,
  GridItem,
  Tab,
  Tabs,
  Button,
  Card,
  Step,
  Steps,
  List,
  Skeleton,
  Field,
  Toast,
} from "vant";

import ActionSheet from "@/layouts/action-sheet/index.vue";
import ContactUs from "@/layouts/contact-us/index.vue";
import Swiper from "@/components/swiper/swipe-patch";

// 导入 vant UI 样式 2 Popup-左弹窗
import "vant/lib/popup/style";
// 导入 vant UI 样式 2 Swipe - SwipeItem - 轮播
import "vant/lib/swipe/style";
import "vant/lib/swipe-item/style";
// 导入 vant UI 样式 2 Lazyload-图片懒加载
import "vant/lib/lazyload/style";
// 导入 vant UI 样式 2 Grid, GridItem - 宫格
import "vant/lib/grid/style";
import "vant/lib/grid-item/style";
// 导入 vant UI 样式 2 Tab, Tabs - 标签页
import "vant/lib/tab/style";
import "vant/lib/tabs/style";
// 导入 vant UI 样式 2 Button - 按钮
import "vant/lib/button/style";
// 导入 vant UI 样式 2 Card - qqw咨询
import "vant/lib/card/style";
// 导入 vant UI 样式 2 Step, Steps - 步骤条
import "vant/lib/step/style";
import "vant/lib/steps/style";
/* List 列表 */
import "vant/lib/list/style";
/* List 骨架屏 */
import "vant/lib/skeleton/style";
/* Field */
import "vant/lib/field/style";
/* Toast */
import "vant/lib/toast/style";

/* 自定义指令 */
import InterSection from "@/directives/intersectionObserver";
Vue.directive("intersection", InterSection);

// 注册 组件
Vue.use(Popup)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Grid)
  .use(GridItem)
  .use(Tab)
  .use(Tabs)
  .use(Button)
  .use(Card)
  .use(Step)
  .use(Steps)
  .use(List)
  .use(Skeleton)
  .use(Field)
  .use(Toast);
/* 注册全局组件 */
Vue.component(ContactUs.name, ContactUs);
Vue.component(ActionSheet.name, ActionSheet);
Vue.component(Swiper.name, Swiper);

// 导入 路由  1
import VueRouter from "vue-router";
// 安装 路由  2
Vue.use(VueRouter);
// 挂载 路由  3  导入自己的router.js 模块
import router from "./router";

// 导入 axios
import axios from "axios";
// 绑定到 Vue 的原型上 通过this.$axios 发送请求
Vue.prototype.$axios = axios;

/* 引入字体 */
import "@/assets/fonts/font.scss";
/* 引入初始化公共样式 */
import "@/assets/styles/style.global.scss";

new Vue({
  el: "#app",
  render: (h) => h(app),
  // 挂载路由 4
  router,
});
