<template>
  <div class="homeContainer qqwCartoon">
      <!-- 页面跟容器 放置背景图层 -->
      <div class="home_content" :style="height">
            <!-- 第一屏 背景+按钮 -->
            <div class="home_background">
                <!-- 中间图 按钮 -->
                <div class="home_down">
                    <div class="home_down_title">
                    <img src="../../image/homeContainer/home_pic_logo.png" />
                    </div>
                    <div class="home_down_btn">
                    <a href="https://g.gd-share.cn/p/bk1l6efx">量身定制APP/小程序</a>
                    
                    </div>
                </div>
                <!-- 动画 下滑按钮 -->
                <div class="home_down_animation">
                    <img src="../../image/homeContainer/icon_down@3x.png" />
                </div>
            </div>
            <!-- 第二屏 -->
            <div>
                <div class="home_page_one">
                    <div class="title">
                        <span>三大赋能</span>
                    </div>
                    <div class="fu_title">用数据赋能让全国超市·厂家<p class="mar0">商业决策更快、更简单、更智能</p></div>
                    <ul class="page_one_ul">
                        <li>
                            <span>
                                <img src="../../image/homeContainer/home_funeng_bg_number@3x.png" alt="">
                            </span>
                        </li>
                        <li>
                            <span>
                                <img src="../../image/homeContainer/home_funeng_bg_commodity.png" alt="">
                            </span>
                        </li>
                        <li>
                            <span>
                                <img src="../../image/homeContainer/home_funeng_bg_operate.png" alt="">
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 第三屏 全球蛙经典客户案例 轮播 -->
            <div class="home_page_two" >
                <div class="page_two_content">
                    <div class="title">
                        <span>全球蛙经典客户案例</span>
                    </div>
                    <!-- Vant UI Swipe -->
                    <van-swipe :autoplay="3000" @change="onChange">
                        <van-swipe-item v-for="(item, index) in qqwClient" :key="index">
                            <div class="app_title">
                                <span>{{item.merchantName}}</span>
                            </div>
                            <div class="app_border"></div>
                            <div class="app_type">{{item.terminal}}</div>
                            <div class="app_img"></div>
                            <img class="app_img_banner" v-lazy="item.img"/>
                            <div class="app_buttom">
                                <span class="app_left">区域：</span>
                                <span class="right">{{item.region}}</span>
                            </div>
                            <div class="app_buttom">
                                <span class="right app_right"><span class="app_left">简介：</span>{{item.introduction}}</span>
                            </div>
                        </van-swipe-item>
                        <div class="custom-indicator" slot="indicator">
                            <span class="font1">{{ current + 1 }}</span><span class="font2">/{{qqwClient.length}}</span>
                        </div>
                    </van-swipe>
                </div>
            </div>
            <!-- 第四屏 全球蛙合作商户 轮播 -->
            <div class="home_page_three">
                <div class="page_three_content">
                    <div class="page_three_title">
                        <span>全球蛙合作商户</span>
                    </div>
                    <div class="page_three_tit">累计战略合作
                        <span>&ensp;300+ &ensp;</span>
                       品牌超市
                    </div>
                    <!-- Vant UI Swipe -->
                    <van-swipe :autoplay="3000"  :indicator-color='indicator'>
                        <van-swipe-item v-for="(item, index) in qqwCooperation" :key="index">
                            <div class="three_itemList">
                                <div class="itemList" v-for="(itemList, indexs) in item " :key="indexs">
                                <img :src="itemList.img" />
                            </div>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </div>
            </div>
            <!-- 第五屏 -->
            <div class="home_page_four">
                <div class="page_three_content">
                   <img src="../../image/homeContainer/home_bg_data@3x.png" alt="">
                </div>
            </div>
            <!-- 第六屏 -->
            <!-- <div class="home_page_five">
                <div class="page_five_content">
                    <div class="page_five_logo">
                        <img src="../../image/home_down_logo@3x.png" alt="">
                    </div>
                    <div class="page_five_www">www.quanqiuwa.com</div>
                    <div class="page_five_font">400-186-6558</div>
                    <div class="page_five_site">山西公司：太原市小店区万立科技大厦8/9层</div>
                    <div class="page_five_site">成都公司：成都市高新区环球中心M5-1905</div>
                    <div class="page_five_records">
                        <span>Copyright © 2013 - 2020 Tencent Cloud.</span>
                        <span>All Rights Reserved. 全球蛙 版权所有</span>
                        <span>
                            <img src="../../image/homeContainer/home_icon_police_logo@3x.png" alt="">
                            晋公网安备 11010802017518 晋B2-20090059-1
                        </span>
                    </div>
                </div>
            </div> -->
            <div  class="fixd_bottom" v-if="showfixd_bottom">
                
                
                <div class="fixd_bottom_left">
                    <a href="tel:15034044866"></a>
                </div>
                <div class="fixd_bottom_right">
                        <a href="https://g.gd-share.cn/p/bk1l6efx"></a>
                </div>
                 <img src="../../image/homeContainer/home_bg_tab@.png" alt="" class="home_bg_tab">
            </div>
      </div>
      
  </div>
</template>

<script>
import { ClientHeights } from '../common/common.js'
import homeContainerJS from '../homeContainer/homeContainer.js'
export default {
    mixins: [ homeContainerJS ],
     data(){
        return {
            height: {
                height: ClientHeights(),
            }
            
        }
    }
};
</script>

<style lang="scss" >
    @import '../common/common.scss';
    @import '../homeContainer/homeContainer.scss';
</style>