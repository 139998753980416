import { getArticleConApi } from '@/apis/home';

export default {
    data() {
        return {
            loading: true,
            article: {}
        }
    },
    created() {
        this.getArticle();
    },
    methods: {
        getArticle() {
            const { id } = this.$route.params;

            if (!id) {
                return false;
            }

            getArticleConApi(id).then(res => {
                const { code, data = {} } = res;

                if (code === 200) {
                    this.article = data;
                }

                this.loading = false;
            }).catch(err => {
                
            });
        },
    },
}