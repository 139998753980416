<template>
    <div class="qqwRecruitment">
        <div><img src="../../image/qqwRecruitment/1.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/2.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/3.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/4.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/5.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/6.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/7.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/8.jpg" alt=""></div>
        <div><img src="../../image/qqwRecruitment/9.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/10.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/11.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/12.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/13.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/14.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/15.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/16.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/17.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/18.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/19.png" alt=""></div>
        <div><img src="../../image/qqwRecruitment/20.png" alt=""></div>
        <div class="more_position">
            <img src="../../image/qqwRecruitment/21.jpg" alt="">
            <a class="more_position" href="https://m.zhipin.com/mpa/html/weijd/weijd-boss/8cbbbfb20abbc3571Hxz29S_FFo~?sid=qr_self"></a>
        </div>


        
    </div>
</template>

<script>
import qqwRecruitmentJS from '../qqwRecruitment/qqwRecruitment.js'
export default {
    mixins: [qqwRecruitmentJS]
}
</script>

<style lang="scss" scoped>
    @import '../qqwRecruitment/qqwRecruitment.scss'
</style>
