/** 
 @description Intersection指令 用于检测元素是否出现在视口
*/

const handlers = new WeakMap();
const ob = new IntersectionObserver(
  function (entries, observer) {
    entries.forEach((entry) => {
      handlers.get(entry.target)(entry.isIntersecting);
    });
  },
  {
    root: null,
    rootMargin: "200px",
    threshold: 0,
  }
);
export default {
  inserted(el, binding) {
    const { arg } = binding;
    const ele = document.querySelector(`.${arg}`);
    ob.observe(ele);
    handlers.set(ele, binding.value);
  },
  
};
