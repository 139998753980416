import {getClient} from '@/apis/home';

export default {
	data() {
		return {
			activeName: 'recommend',
			qqwClient_recommend: [
				{}
			],
			list: [
				{
					specialRecommendation: true,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/meitehao.jpg",
					province: '山西',
					city: '太原',
					company: '山西美特好连锁超市股份有限公司',
					title: '美特好优鲜到家',
					description: '全球蛙助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线，全面服务420万太原市民。',
					tags: ['到家配送', '小程序', 'APP'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/guan.png",
					province: '福建',
					city: '',
					company: '冠超市',
					title: '冠到家  小冠优鲜',
					description: '冠超市已经完成了福建、云南、广西3省20城30多家门店的在线服务，2020年618单日线上拼团销售突破100万！双11线上销售115万！',
					tags: ['小程序', '线上商城', '到家配送', '线下自提'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/shouhang.jpg",
					province: '北京',
					city: '',
					company: '北京首航超市',
					title: 'Sofly首航优选',
					description: '全球蛙为首航超市打造全渠道运营、数智化营销、增量供应链，通过直播、拼团、分销、领券等多种营销活动，让首航超市实现数智化新增长。',
					tags: ['新增长', '数智化', '小程序'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/quanbao.jpg",
					province: '天津',
					city: '',
					company: '天津劝宝超市有限责任公司',
					title: '劝宝优选',
					description: '全球蛙为"劝宝优选”小程序首次上线提供进口洋货、原创国货、农特土货等C2M海量好物，最终436%超额完成销售目标！C2M销售占比高达31.48%。',
					tags: ['小程序', '增量供应链', 'C2M'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/zhongshang.JPG",
					province: '湖北',
					city: '武汉',
					company: '武汉中商超市连锁有限责任公司',
					title: '中商超市社区拼团',
					description: '"中商超市社区拼团”小程序双十一单日销售突破202万元，是全球蛙与中商数智化战略合作新里程！',
					tags: ['小程序', '社区拼团', '百万商户'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/chaoshifa.jpg",
					province: '河北',
					city: '张家口',
					company: '张家口市超市发连锁超市',
					title: '超市发优选',
					description: '"超市发优选”小程序首期拼团704%超额完成销售目标，双11当天销售突破121万元！全球蛙助力超市发真正实现数智化新增长！',
					tags: ['小程序', '百万商户', '六大在线'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/huiyou.jpg",
					province: '河北',
					city: '',
					company: '惠友商业连锁发展有限公司',
					title: '惠友到家',
					description: '全球蛙为惠友超市免费定制专属小程序"惠友到家”，首次上线单日销售72万，363%超额完成销售目标，收获数智化新增长！',
					tags: ['小程序', '新增长', '数智技术'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/wandefu.jpg",
					province: '山西',
					city: '晋城',
					company: '山西省晋城万德福超市',
					title: '万德福到家',
					description: '"万德福到家”小程序首期上线240%超额完成销售目标！全球蛙助力万德福超市实现线下+线下全渠道数智化升级，为顾客提供美好地购物体验。',
					tags: ['小程序', '小程序', '新体验'],
				},
				{
					specialRecommendation: false,
					contentImg: "https://wxauth.quanqiuwa.com/guanwanganli/xiaomei.jpg",
					province: '山西',
					city: '太原',
					company: '山西小美一日菜超市有限公司',
					title: '小美一日菜',
					description: '全球蛙帮助小美一日菜打造线上线下全渠道社区生鲜店，2020年615拼团活动中单日拼团销售近15万！线上销售是线下的5倍！',
					tags: ['小程序', '新模式', '社区拼团'],
				},
			]
		}
	},
	created() {
		this.getClientData();
	},
	methods: {
		async getClientData() {
			// const res = await getClient();
		}
	}
}
