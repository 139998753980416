import { Swipe } from "vant";
export default {
  name: "vanSwipe",
  extends: Swipe,
  methods: {
    getTargetOffset: function getTargetOffset(targetActive, offset) {
      if (offset === void 0) {
        offset = 0;
      }

      var currentPosition = targetActive * this.size;
      var targetOffset = offset - currentPosition;
      return targetOffset;
    },
    move: function move(_ref) {
      var _ref$pace = _ref.pace,
        pace = _ref$pace === void 0 ? 0 : _ref$pace,
        emitChange = _ref.emitChange;
      var loop = this.loop,
        count = this.count,
        active = this.active,
        children = this.children,
        trackSize = this.trackSize,
        minOffset = this.minOffset;

      if (count <= 1) {
        return;
      }

      var targetActive = this.getTargetActive(pace);
      var targetOffset = this.getTargetOffset(targetActive);

      if (loop) {
        if (children[0] && targetOffset !== minOffset) {
          var outRightBound = targetOffset < minOffset;
          children[0].offset = outRightBound ? trackSize : 0;
        }

        if (children[count - 1] && targetOffset !== 0) {
          var outLeftBound = targetOffset > 0;
          children[count - 1].offset = outLeftBound ? -trackSize : 0;
        }
      }

      this.active = targetActive;
      this.offset = targetOffset;

      if (emitChange && targetActive !== active) {
        this.$emit("change", this.activeIndicator);
      }
    },
  },
};
