<template>
  <div
    v-intersection:copy-right-container="handleVisible"
    :class="['contact-icon', visible ? 'ease-in' : 'ease-out']"
  >
    <img @click="handlePhone" src="@/image/layouts/btn_phone_pop@3x.png" />
  </div>
</template>

<script>
export default {
  data: () => ({
    visible: false,
  }),
  methods: {
    handleVisible(isIntersecting) {
      this.visible = isIntersecting;
    },
    handlePhone() {
      if (this.visible) {
        const link = document.createElement("a");
        link.href = "tel:15034044866";
        link.dispatchEvent(new MouseEvent("click"));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.contact-icon {
  position: fixed;
  right: 30px;
  bottom: 150px;
  width: 140px;
  height: 140px;
  transition: all 1s;
  img {
    width: 100%;
    height: 100%;
  }
}
.ease-in {
  opacity: 1;
  animation-timing-function: ease-in;
}
.ease-out {
  opacity: 0;
  animation-timing-function: ease-out;
}
</style>
