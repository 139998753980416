<template>
    <div class="qqwTradeScheme">
        <!-- 行业解决方案 -->
        <!-- 第一屏 -->
        <div class="home_page_one">
            <div class="page_one_content">
               
                 <a href="https://g.gd-share.cn/p/bk1l6efx"> <img src="../../image/qqwTradeScheme/banner.png" alt=""></a>
            </div>
        </div>
        <!-- 第二屏 -->
        <div class="home_page_two">
            <div class="page_two_content">
                <div class="page_twoTop">
                    <div>传统超市面临转型困境，</div>
                    <div>你是否也被这些问题困扰？</div>
                </div>
               
                <van-grid :gutter="10" :column-num="2" :center='false'>
                    <van-grid-item v-for="(item, index) in page_two_cont" :key="index" >
                       
                        <div class="jianju">
                        <div class="vanTop">{{item.vanTop}}</div>
                        <div class="vanCont">{{item.vanCont}}</div>
                        </div>
                        <div class="vanBott">{{item.vanBott}}</div>
                        <div class="vanBott">{{item.vanBotts}}</div>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <!-- 第三屏 -->
        <div class="home_page_three">
            <div class="page_three_content">
                <div class="page_threeTop">
                    <span>三大赋能</span>
                </div>
                <div class="fu_title">用数据赋能让全国超市·厂家商业决策更快、更简单、更智能</div>
            </div>
            <!-- 
                v-model="activeName" 绑定当前选中标签的标识符
                sticky 粘性定位布局 自动吸顶
                offset-top='70px' 粘性定位布局下与顶部的最小距离，支持 px vw vh rem 单位，默认 px
                animated 切换标签内容时的转场动画
                swipeable 手势滑动切换
             -->
            <van-tabs v-model="activeName" 
                sticky offset-top='45px' 
                animated 
                swipeable 
                color='#5cb531' 
                line-width='70' 
                ref="checkbox">
                <van-tab title="数字新技术" name="a"  title-class='three_van_tabs'>
                    <div class="threeContTop">为全国超市量身定制专属APP/小程序</div>
                    <div class="threeContLabel">
                        <img src="../../image/qqwTradeScheme/programme_funeng_bg1@3x.png" alt="" class="img1">
                    </div>
                    <div class="threeContExplain">
                        <span>助力超市全面升级人货场，实现门店、顾客、导购、商品、交易、管理在线化！</span>
                    </div>
                    <div class="threeContbotton">
                        <img src="../../image/qqwTradeScheme/programme_funeng_pic_number.png" alt="">
                    </div>
                </van-tab>
                <van-tab title="增量新商品" name="b">
                    <div class="threeContTop">全球优质C2M商品反向嵌入APP/小程序</div>
                    <div class="threeContLabel">
                        <img src="../../image/qqwTradeScheme/programme_funeng_bg2@3x.png" alt="" class="img2">
                    </div>
                    <div class="threeContExplain">
                        <span>联合百万优质供应商资源，与超市共创增量商品，规模联采一件代发，共享供应链红利!</span>
                    </div>
                    <div class="threeContbotton">
                        <img src="../../image/qqwTradeScheme/programme_funeng_pic_commodity@3x.png" alt="">
                    </div>
                </van-tab>
                <van-tab title="导购新运营" name="c">
                    <div class="threeContTop">上千套运营模板、一对一运营培训</div>
                    <div class="threeContLabel">
                        <img src="../../image/qqwTradeScheme/programme_funeng_bg3@3x.png" alt=""  class="img3">
                    </div>
                    <div class="threeContExplain">
                        <span>赋能超市拥有分销、直播、营销等全套运营能力，提供上千套全渠道运营模板，让超市聚集人气更简单!</span>
                    </div>
                    <div class="threeContbotton">
                        <img src="../../image/qqwTradeScheme/programme_funeng_pic_operate2@3x.png" alt="">
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <!-- 第四屏 -->
        <div class="home_page_four">
            <div class="page_four_content">
                <div class="page_fourTop">
                    <span>新技术</span>
                </div>
                <div class="fu_title">全面升级人货场</div>
                <div class="vanTop_cont">
                    <div class="vanTop_contItem" v-for="(item, index) in page_four_cont" :key="index">
                        <div class="vanTop">
                            <img :src="item.vanTop" alt="">
                        </div>
                        <div class="vanCont">{{item.vanCont}}</div>
                        <div class="vanBott">{{item.vanBott}}</div>
                        <div class="vanBott">{{item.vanBotts}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第五屏 -->
        <div class="home_page_five">
            <div class="page_five_content">
                <div class="page_fiveTop">
                    <span>新商品</span>
                </div>
                <div class="fu_title">共享增量供应链</div>
                <van-grid :column-num="3" :border='false'>
                    <van-grid-item v-for="(item, index) in page_five_conts" :key="index">
                        <div class="vanTop">
                            <img :src="item.vanTop" alt="">
                        </div>
                        <div class="vanCont">{{item.vanCont}}</div>
                        <div class="vanBott">{{item.vanBottOne}}</div>
                        <div class="vanBott">{{item.vanBottTwo}}</div>
                        <div class="vanBott">{{item.vanBottThree}}</div>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <!-- 第六屏 -->
        <div class="home_page_six">
            <div class="page_six_content">
                <div class="page_sixTop">
                    <span>新运营</span>
                </div>
                <div class="fu_title">赋能营销全场景</div>
                
                <div class="vanTop_cont">
                    <div class="vanTop_contItem" v-for="(item, index) in page_six_conts" :key="index">
                        <div class="vanTop">
                            <img :src="item.vanTop" alt="">
                        </div>
                        <div class="vanCont">{{item.vanCont}}</div>
                        <div class="vanBott">{{item.vanBottOne}}</div>
                        <div class="vanBott">{{item.vanBottTwo}}</div>
                        <div class="vanBott">{{item.vanBottThree}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第七屏 -->
        <div class="home_page_seven">
            <div class="page_seven_content">
                <div class="sevenTop">用数据赋能让全国超市·厂家商业决策更快、更简单、更智能</div>
                <div class="sevenCont">全球蛙作为全国超市新零售平台，通过新技术、新商品、新运营三大赋能，为全国超市量身定制开发专属APP/小程序，反向嵌入C2M增量新品供应链，为超市导购赋能会员服务和商品运营能力，用数据赋能让全国超市·厂家商业决策更快、更简单、更智能！</div>
                <div class="sevenBtn">
                      <a href="https://g.gd-share.cn/p/bk1l6efx"> 了解详情 </a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import qqwTradeSchemeJS from '../qqwTradeScheme/qqwTradeScheme.js'
export default {
    mixins: [qqwTradeSchemeJS]
}
</script>

<style lang="scss" >
    @import '../qqwTradeScheme/qqwTradeScheme.scss'
</style>