<template>
  <div class="qqwClient iphone">
    <!-- 客户案例 -->
        <van-tabs v-model="activeName"
                  sticky offset-top='45px'
                  animated
                  swipeable
                  color='#5cb531'
                  line-width='70'>
          <van-tab title="经典客户案例" name="recommend" title-class='three_van_tabs'>
    <div class="case_div" v-for="(item, index) in list" :key="index">
      <div class="img_div">
        <img v-if="item.specialRecommendation" class="tj_img" src="../../image/qqwClient/washeng_label_tebie.png"/>
        <img title="美特好优鲜到家" alt="美特好优鲜到家" class="home_img"
             :src="item.contentImg"/>
      </div>
      <div class="item-content">
        <div class="cast_city">
          <span>{{ item.province }}</span>
          <span class="qie" v-if="item.city">|</span>
          <span v-if="item.city">{{ item.city }}</span>
          <span class="pa">{{ item.company }}</span>
        </div>
        <div class="case_content">
          <div class="title">{{ item.title }}</div>
          <div class="content">{{ item.description }}</div>
        </div>
        <div class="case_terminal" v-if="item.tags.length > 0">
          <div class="small_terminal" v-for="(tagItem, tagIndex) in item.tags" :key="tagIndex">{{ tagItem }}</div>
        </div>
      </div>

    </div>
          </van-tab>
    <!--      <van-tab title="全部客户案例" name="complete">-->
    <!--        <div class="case_div">-->
    <!--          <div id="index_div">-->
    <!--            <div class="img_div">-->
    <!--              <img class="tj_img" src="../../image/qqwClient/washeng_label_tebie.png"/>-->
    <!--              <img title="美特好优鲜到家" alt="美特好优鲜到家" class="home_img"-->
    <!--                   src="../../image/qqwClient/washeng_meitehao_2.png"/>-->
    <!--            </div>-->
    <!--            <div class="cast_city">-->
    <!--              <span>山西</span>-->
    <!--              <span class="qie">|</span>-->
    <!--              <span>太原</span>-->
    <!--              <span class="pa">山西美特好连锁超市股份有限公司</span>-->
    <!--            </div>-->
    <!--            <div class="case_content">-->
    <!--              <div class="title">美特好优鲜到家</div>-->
    <!--              <div class="content">全球蛙助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线，全面服务420万太原市民。</div>-->
    <!--            </div>-->
    <!--            <div class="case_terminal">-->
    <!--              <div class="small_terminal">-->
    <!--                <div class="terminal_ndiv">-->
    <!--                  <span>到家配送</span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="small_terminal">-->
    <!--                <div class="terminal_ndiv">-->
    <!--                  <span>小程序 </span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--              <div class="small_terminal">-->
    <!--                <div class="terminal_ndiv">-->
    <!--                  <span>APP </span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </van-tab>-->
        </van-tabs>
        <div class="more"> 没有更多了</div>
  </div>
</template>
<script>
import qqwClientJS from '../qqwClient/qqwClient.js'

export default {
  mixins: [qqwClientJS]
}
</script>

<style lang="scss">
@import '../qqwClient/qqwClient.scss'
</style>
