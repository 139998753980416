import axios from 'axios'
// 创建axios实例
const service = axios.create({
  baseURL: '', 
  timeout: 30000, // 请求超时时间,
  withCredentials: true
})

// request拦截器
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    console.log(error)
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if (response.status < 300) {
      return response.data;
    }
    return Promise.reject(error)
  },
  error => {
    return Promise.reject(error)
  }
)

class Majax {
  constructor() {
    this.service = service;
  }

  sendApi ({ url = '', method = 'GET', params = {}, headers = {} }) {
    headers = {
      ...headers
    }

    if (method === 'GET') {
      return this.service.get(url, {
        params,
        headers
      });
    } else {
      return this.service.post(url, params, {
        headers
      });
    }
  }
}

const mAjax = new Majax();

export default mAjax.sendApi.bind(mAjax);
