<template>
    <div class="qqwMessage">
        <div class="home_page_one">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="listOnLoad"
            >
                <div
                    class="page_one_content"
                    v-for="(item, index) in qqwMessages"
                    @click.stop="goArticle(item)"
                    :key="index"
                >
                    <div class="page_one_left">
                        <img :src="item.relatedInformation.img" alt="">
                    </div>
                    <div class="page_one_right">
                        <div class="one_right_top">{{item.relatedInformation.title}}</div>
                        <div class="one_right_bot">
                            <!-- <div class="one_right_ding" v-if="item.one_right_ding">
                                <img src="../../image/qqwMessage/logo_wechat_fe862af.png" alt="">
                                <span>置顶</span>
                            </div> -->
                            <div class="one_right_icon">
                                <img src="../../image/qqwMessage/logo_wechat_fe862af.png" alt="">
                                <span>全球蛙小秘书</span>
                            </div>
                            <div class="one_right_time">
                                <span>{{item.relatedInformation.createdAt}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import qqwMessageJS from '../qqwMessage/qqwMessage.js'
export default {
    mixins: [qqwMessageJS]
}
</script>

<style lang="scss" scoped>
    @import '../qqwMessage/qqwMessage.scss'
</style>
