<template>
  <div class="action-sheet" v-if="menuOrArticle.length">
    <slot name="extra-title"></slot>
    <div
      v-for="route in menuOrArticle"
      :key="route.path"
      class="route"
      @click="handleRoute(route)"
    >
      <span class="single-line-overflow">{{ route.title }}</span>
      <span v-if="module && route.title.length > 20">》</span>
      <img
        v-if="!module"
        class="route-more-image"
        src="@/image/layouts/btn_more_listing@3x.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { getArticle } from "@/apis/article";
export default {
  name: "ActionSheet",
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    module: {
      type: String,
      default: "",
    },
  },
  data: () => ({ article: [] }),
  computed: {
    menuOrArticle() {
      const { module, menu, article } = this;
      return module ? article : menu;
    },
  },
  async mounted() {
    if (this.module) {
      const {
        code = "",
        data: { list = [] } = {},
        message: msg = "",
      } = await getArticle({
        module: this.module,
        pageIndex: 1,
        pageSize: 2,
      });
      if (code !== "0") return this.$toast.fail(msg);
      this.article = list.map(
        ({ articleName: title, articleLink: path, id }) => ({ id, title, path })
      );
    }
  },
  methods: {
    handleRoute({ path }) {
      if (path.startsWith("http")) return window.open(path);
      if (path.startsWith("www")) return window.open(`http://${path}`);
      this.$router.push(path);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.action-sheet {
  width: 100%;
  background-color: $bgColorfff;
  padding: 0 50px;
  box-sizing: border-box;
  .route {
    height: 100px;
    font-size: 28px;
    font-family: PingFang SC-Semibold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    .route-more-image {
      width: 30px;
      height: 30px;
    }
  }
  .single-line-overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
