<template>
  <div class="home_nav">
    <div class="home_logo_img">
      <router-link to="/home" aria-current="page" class="home_nav_ul_a">
        <img
          src="@/image/logo/home_logo@3x.png"
          alt="全球蛙 - 超市新零售平台"
          title="全球蛙 - 超市新零售平台"
        />
      </router-link>
    </div>
    <img
      ref="home_nav_icon"
      class="home_nav_icon"
      :src="sideMenuIcon"
      @click="handlePopup"
    />
    <!-- 侧边栏导航 -->
    <side-menu :visible="visible" :handlePopup="handlePopup" />
  </div>
</template>

<script>
import SideMenu from "@/layouts/side-menu/index.vue";
export default {
  components: {
    SideMenu,
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    sideMenuIcon() {
      return require(this.visible
        ? "@/image/layouts/home_but_more_close@3x.png"
        : "@/image/layouts/home_but_more@3x.png");
    },
  },
  methods: {
    handlePopup() {
      this.visible = !this.visible;
      this.$refs["home_nav_icon"].classList.add("animation-side-in");
      setTimeout(() => {
        this.$refs["home_nav_icon"].classList.remove("animation-side-in");
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.home_nav {
  width: 750px;
  background: $bgColorfff;
  position: fixed;
  z-index: 100;
  height: 88px;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 15px;
  box-sizing: border-box;
  .home_logo_img {
    width: 164px;
    height: 65px;
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .home_nav_icon {
    width: 48px;
    height: 48px;
  }
  .animation-side-in {
    animation: sideIn 0.5s ease-in;
  }
  @keyframes sideIn {
    from {
      transform: translateX(60px);
    }
    to {
      transform: translateX(0px);
    }
  }
}
</style>
