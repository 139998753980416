var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qqwConsociation"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"home_page_three"},[_c('div',{staticClass:"page_three_content"},[_vm._m(2),_c('van-grid',{attrs:{"gutter":10,"column-num":2,"center":false}},_vm._l((_vm.page_three_cont),function(item,index){return _c('van-grid-item',{key:index},[_c('div',{staticClass:"vanTop"},[_c('img',{attrs:{"src":item.vanTop,"alt":""}})])])}),1)],1)]),_c('div',{staticClass:"home_page_four"},[_c('div',{staticClass:"page_four_content"},[_vm._m(3),_vm._l((_vm.page_four_cont),function(item,index){return _c('div',{key:index,staticClass:"img_se"},[_c('div',{staticClass:"vanTop"},[_c('img',{attrs:{"src":item.vanTop,"alt":""}})])])})],2)]),_c('div',{staticClass:"home_page_five"},[_c('div',{staticClass:"page_five_content"},[_vm._m(4),_vm._l((_vm.page_five_cont),function(item,index){return _c('div',{key:index,staticClass:"img_se"},[_c('div',{staticClass:"vanTop"},[_c('img',{attrs:{"src":item.vanTop,"alt":""}})])])})],2)]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_page_one"},[_c('div',{staticClass:"page_one_content"},[_c('img',{attrs:{"src":require("../../image/qqwConsociation/cooperation_banner@.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_page_two"},[_c('div',{staticClass:"page_two_content"},[_c('div',{staticClass:"page_twoTop"},[_c('span',[_vm._v("商超合作")])]),_c('div',{staticClass:"fu_title"},[_vm._v("全球蛙以“用数据赋能让全国超市·厂家商业决策更快、更简单、更智能”为使命，通过数字新技术、增量新商品、导购新运营三大赋能，为全国超市量身定制专属APP和小程序，让超市快速拥有数智能力；赋能超市导购拥有会员服务和商品营销能力，让导购实现收入倍增；联合百万供应商资源与超市共创增量商品，让超市实现利润增长！")]),_c('div',{staticClass:"fu_title"},[_vm._v("全球蛙立足山西，走向全国，截止当前业务覆盖四川、福建、广西、云南、湖北、湖南、浙江、山东等30个省级行政区，与福建冠超市、北京首航超市、张家口超市发超市、武汉中商超市等300多家品牌连锁企业达成战略合作，上线超市均通过全球蛙赋能实现了环比20%到50%的新增长！荣获国家商务部“数字商务企业”、科技部“高新技术企业”、工信部“国家级疫情防控重点保障企业”、中国连锁经营协会CCFA“年度最佳平台赋能零售创新奖”等多项荣誉。未来，全球蛙将携手万家超市百万导购为亿万用户提供高品质高性价比的商品和专业贴心的服务！ ")]),_c('div',{staticClass:"page_twoImg"},[_c('img',{attrs:{"src":require("../../image/qqwConsociation/cooperation_img_schz@.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_threeTop"},[_c('div',[_vm._v("平台价值")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_fourTop"},[_c('div',[_vm._v("竞争优势")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_fiveTop"},[_c('div',[_vm._v("合作流程")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_page_six"},[_c('div',{staticClass:"page_six_content"},[_c('div',{staticClass:"sixTop"},[_vm._v("用数据赋能让全国超市·厂家商业决策更快、更简单、更智能")]),_c('div',{staticClass:"sixCont"},[_vm._v("为您量身定制专属APP/小程序")]),_c('div',{staticClass:"sixBtn"},[_c('a',{attrs:{"href":"tel:15034044866"}},[_vm._v("立即联系")])])])])
}]

export { render, staticRenderFns }