<template>
  <div class="contact-us">
    <div class="contact-us-title">请与我们联系</div>
    <div class="contact-us-tips">
      将有专人为您提供经营建议、解决方案、专业答疑
    </div>
    <div class="contact-phone-container">
      <van-field
        v-model="phone"
        class="contact-phone-input"
        placeholder="请输入您的手机号码"
        :maxlength="11"
        type="tel"
      />
    </div>
    <div class="contact-phone-botton" @click="handleReservation">
      <img src="@/image/homeContainer/btn_phone_home@3x.png" alt="" />
    </div>
  </div>
</template>

<script>
import { appointmentCallBack } from "@/apis/contact";
export default {
  name: "ContactUs",
  data: () => ({
    phone: "",
  }),
  methods: {
    async handleReservation() {
      const reg = new RegExp(/^1[3-9]\d{9}$/);
      const { phone } = this;
      if (!reg.test(phone)) {
        this.$toast.fail("请输入正确格式的手机号");
        return false;
      }
      try {
        const result = await appointmentCallBack({ phone });
        const {
          data: { code = "", msg = "" },
        } = result;
        if (code === "0") {
          this.$toast.success(msg);
          this.phone = "";
        } else {
          this.$toast.fail(msg);
        }
      } catch (err) {
        this.$toast.fail("服务器出错");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.contact-us {
  padding: 60px 0;
  box-sizing: border-box;
  background-color: $bgColorfff;
  .contact-us-title {
    padding: 0 50px;
    box-sizing: border-box;
    font-size: 40px;
    font-family: Alibaba PuHuiTi-Bold;
    color: $textColor222;
  }
  .contact-us-tips {
    padding: 0 50px;
    box-sizing: border-box;
    font-size: 26px;
    font-family: PingFang SC-Regular;
    color: $textColor999;
    margin: 10px 0 20px 0;
  }
  .contact-phone-container {
    padding: 0 50px;
    box-sizing: border-box;
    .contact-phone-input {
      background-color: #f5f5f5 !important;
      border-radius: 16px;
      &::placeholder {
        color: $textColor999;
        font-family: PingFang SC, PingFang SC-Regular;
        font-size: 26px;
      }
    }
  }
  .contact-phone-botton {
    width: 750px;
    height: 84px;
    margin-top: 40px;
    img {
      width: 100%;
    }
  }
}
</style>
