export default {
    data() {
        return {
            //第三屏 数据
            page_three_cont: [
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_B@.png'),
                },
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_c@.png'),
                },
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_D@.png'),
                },
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_M@.png'),
                }
            ],
            //第四屏 数据
            page_four_cont: [
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_quanqiuwa@.png'),
                },
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_o2o@.png'),
                }
            ],
            //第五屏 数据
            page_five_cont: [
                {
                    vanTop: require('../../image/qqwConsociation/cooperation_bg_hezuoliucheng@.png'),
                }
            ],
        }
    },
}