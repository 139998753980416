export default {
    data() {
        return {
            //第三屏 数据
            page_three_conts: [
                {
                    vanTop: require('../../image/qqwAboutMy/us_bg_shiming@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/us_bg_yuanjing@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/us_bg_jiazhiguan@.png'),
                }
            ],
            //第五屏 数据
            page_five_conts: [
                {
                    vanTop: require('../../image/qqwAboutMy/1@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/2@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/3@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/4@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/5@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/6@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/7@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/8@.png'),
                },
                {
                    vanTop: require('../../image/qqwAboutMy/9@.jpg')
                }                
            ]
        }
    },
}