<template>
  <div class="copy-right-container">
    <div class="copy-right-image">
      <img src="@/image/logo/copy-right@3x.png" />
    </div>
    <div class="copy-right-link" @click="handleOpenportalWebsite">
      <img src="" alt="" />
      <span>quanqiuwa.com</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleOpenportalWebsite() {
      window.open("https://quanqiuwa.com");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.copy-right-container {
  width: 750px;
  .copy-right-image {
    height: 168px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bgColorfff;
    img {
      width: 750px;
      height: 44px;
    }
  }
  .copy-right-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: $bgColorfa;
    img,
    span {
      cursor: pointer;
    }
    span {
      font-size: 24px;
      font-family: PingFang SC-Medium;
      color: $textColor333;
    }
  }
}
</style>
