/* 首页 */
import Home from "@/views/home/index.vue";
import RouterView from "@/components/router-view/index.vue";
export const routes = [
  { path: "/index", component: Home, title: "首页", isMenu: true },
  {
    path: "/retail",
    isMenu: true,
    // title: "零售商解决方案",
    title: "零售商数智化技术产品&解决方案",
    component: RouterView,
    redirect: "/retail",
    children: [
      {
        path: "/retail",
        component: () => import("@/views/retail/index.vue"),
      },
      {
        path: "/retail/technical-power",
        component: () => import("@/views/retail/subviews/technical-power.vue"),
      },
      {
        path: "/retail/data-power",
        component: () => import("@/views/retail/subviews/data-power.vue"),
      },
      {
        path: "/retail/operate-power",
        component: () => import("@/views/retail/subviews/operate-power.vue"),
      },
      {
        path: "/retail/marketing-power",
        component: () => import("@/views/retail/subviews/marketing-power.vue"),
      },
      {
        path: "/retail/commodity-power",
        component: () => import("@/views/retail/subviews/commodity-power.vue"),
      },
      {
        path: "/retail/explosives-power",
        component: () => import("@/views/retail/subviews/explosives-power.vue"),
      },
      {
        path: "/retail/service-platform",
        component: () => import("@/views/retail/subviews/service-platform.vue"),
      },
    ],
  },
  {
    path: "/brand",
    isMenu: true,
    // title: "品牌商解决方案",
    title: "品牌商数智化技术产品&解决方案",
    component: RouterView,
    redirect: "/brand",
    children: [
      {
        path: "/brand",
        component: () => import("@/views/brand/index.vue"),
      },
      {
        path: "/brand/technical-power",
        component: () => import("@/views/brand/subviews/technical-power.vue"),
      },
      {
        path: "/brand/data-power",
        component: () => import("@/views/brand/subviews/data-power.vue"),
      },
      {
        path: "/brand/operate-power",
        component: () => import("@/views/brand/subviews/operate-power.vue"),
      },
      {
        path: "/brand/marketing-power",
        component: () => import("@/views/brand/subviews/marketing-power.vue"),
      },
      {
        path: "/brand/commodity-power",
        component: () => import("@/views/brand/subviews/commodity-power.vue"),
      },
      {
        path: "/brand/explosives-power",
        component: () => import("@/views/brand/subviews/explosives-power.vue"),
      },
      {
        path: "/brand/service-platform",
        component: () => import("@/views/brand/subviews/service-platform.vue"),
      },
    ],
  },
  {
    path: "/region",
    isMenu: true,
    // title: "区域经济解决方案",
    title: "区域经济建设数智化技术产品&解决方案",
    component: RouterView,
    redirect: "/region",
    children: [
      {
        path: "/region",
        component: () => import("@/views/region/index.vue"),
      },
      {
        path: "/region/data-fusion",
        component: () => import("@/views/region/subviews/data-fusion.vue"),
      },
      {
        path: "/region/county-level",
        component: () => import("@/views/region/subviews/county-level.vue"),
      },
    ],
  },
  {
    path: "/about-us",
    isMenu: true,
    title: "关于我们",
    component: () => import("@/views/about-us/index.vue"),
  },
  {
    path: "/contact-us",
    isMenu: true,
    title: "联系我们",
    component: () => import("@/views/contact-us/index.vue"),
  },
];
