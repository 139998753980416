import { ClientHeights } from "@/components/common/common.js";
import enumerableRoutesMixin from "@/mixins/enumerableRoutes";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
export default {
  mixins: [enumerableRoutesMixin],
  data() {
    return {
      height: {
        height: ClientHeights(),
      },
      programme: [
        {
          path: "/retail",
          title: "零售商数智化技术产品&解决方案",
          content:
            "全球蛙通过为零售商提供B2b2C、B2B、OMS等独具特色的 “ 美蛙零售数智化业务平台 ” ，使得零售商交易更加灵活方便、管理更加协同高效、系统更加开放连接…",
          src: require("@/image/homeContainer/img_retail_home@3x.png"),
        },
        {
          path: "/brand",
          title: "品牌商数智化技术产品&解决方案",
          content:
            "全球蛙以数字科技，为品牌商构建S2B2B、S2B2b2C、B2b2C等商品全链路、销售全渠道、协同最高效的 “ 美蛙品牌数智化业务平台 ” ，实现品牌商业务的持续增长…",
          src: require("@/image/homeContainer/img_brand_home@3x.png"),
        },
        {
          path: "/region",
          title: "区域经济建设数智化技术产品&解决方案",
          content:
            "全球蛙积极探索数字乡村建设新模式，为区域打造独特的B2b2C、OMS、数据中台等数智化业务平台，加速数字经济产业聚集，开展乡村数字新建设…",
          src: require("@/image/homeContainer/img_village_home@3x.png"),
        },
      ],
      cases: [
        {
          id: 1,
          tips: "零售伙伴",
          title: "优秀的企业都找全球蛙",
          src: require("@/image/homeContainer/img_300retail_home@3x.png"),
          brandIcon: [
            [
              require("@/image/homeContainer/img_01_retail@3x.png"),
              require("@/image/homeContainer/img_02_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_03_retail@3x.png"),
              require("@/image/homeContainer/img_04_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_05_retail@3x.png"),
              require("@/image/homeContainer/img_06_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_07_retail@3x.png"),
              require("@/image/homeContainer/img_08_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_09_retail@3x.png"),
              require("@/image/homeContainer/img_10_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_11_retail@3x.png"),
              require("@/image/homeContainer/img_12_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_13_retail@3x.png"),
              require("@/image/homeContainer/img_14_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_15_retail@3x.png"),
              require("@/image/homeContainer/img_16_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_17_retail@3x.png"),
              require("@/image/homeContainer/img_18_retail@3x.png"),
            ],
          ],
          brand: [
            require("@/image/homeContainer/img_mthqs_retail@3x.png"),
            require("@/image/homeContainer/img_gdjqs_retail@3x.png"),
            require("@/image/homeContainer/img_gcs_retail@3x.png"),
            require("@/image/homeContainer/img_bjsh_retail@3x.png"),
            require("@/image/homeContainer/img_hbcsf_retail@3x.png"),
            require("@/image/homeContainer/img_whzs_retail@3x.png"),
            require("@/image/homeContainer/img_hncs_retail@3x.png"),
            require("@/image/homeContainer/img_hbhy_retail@3x.png"),
            require("@/image/homeContainer/img_fyxg_retail@3x.png"),
          ],
        },
        {
          id: 2,
          tips: "品牌",
          title: "优秀的品牌都找全球蛙",
          src: require("@/image/homeContainer/img_100brand_home@3x.png"),
          brandIcon: [
            [
              require("@/image/homeContainer/img_01_brand_retail@3x.png"),
              require("@/image/homeContainer/img_02_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_03_brand_retail@3x.png"),
              require("@/image/homeContainer/img_04_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_05_brand_retail@3x.png"),
              require("@/image/homeContainer/img_06_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_07_brand_retail@3x.png"),
              require("@/image/homeContainer/img_08_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_09_brand_retail@3x.png"),
              require("@/image/homeContainer/img_10_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_11_brand_retail@3x.png"),
              require("@/image/homeContainer/img_12_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_13_brand_retail@3x.png"),
              require("@/image/homeContainer/img_14_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_15_brand_retail@3x.png"),
              require("@/image/homeContainer/img_16_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_17_brand_retail@3x.png"),
              require("@/image/homeContainer/img_18_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_19_brand_retail@3x.png"),
              require("@/image/homeContainer/img_20_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_21_brand_retail@3x.png"),
              require("@/image/homeContainer/img_22_brand_retail@3x.png"),
            ],
            [
              require("@/image/homeContainer/img_23_brand_retail@3x.png"),
              require("@/image/homeContainer/img_24_brand_retail@3x.png"),
            ],
          ],
          brand: [
            require("@/image/homeContainer/img_yz_brand@3x.png"),
            require("@/image/homeContainer/img_jnmy_brand@3x.png"),
            require("@/image/homeContainer/img_jz_brand@3x.png"),
            require("@/image/homeContainer/img_yt_brand@3x.png"),
            require("@/image/homeContainer/img_jsh_brand@3x.png"),
            require("@/image/homeContainer/img_sdlhqs_xyy_brand@3x.png"),
            require("@/image/homeContainer/img_bj_brand@3x.png"),
            require("@/image/homeContainer/img_ryytn_brand@3x.png"),
            require("@/image/homeContainer/img_3c_brand@3x.png"),
          ],
        },
      ],
    };
  },
  mounted() {
    new Swiper(".swiper", {
      loop: true, // 循环模式选项
      initialSlide: 0,
      slidesPerView: "auto",
      speed: 500,
      centeredSlides: true,
      spaceBetween: 20,
      autoHeight: true,
    });
  },
};
