<template>
    <div class="qqwDetail">
        <van-skeleton title :row="10" :loading="loading">
            <div class="article-title">{{article.title}}</div>
            <div class="article-box" v-html="article.articleBody"></div>
        </van-skeleton>
    </div>
</template>

<script>
import qqwDetail from '../qqwDetail/qqwDetail.js'
export default {
    mixins: [qqwDetail]
}
</script>

<style lang="scss" scoped>
    @import '../qqwDetail/qqwDetail.scss'
</style>