import { getArticleApi } from '@/apis/home';

export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            pageIndex: 0,
            pageSize: 10,
            errorHttp: 0,
            qqwMessages: []
        }
    },
    created() {
        // this.listOnLoad();
    },
    methods: {
        listOnLoad() {
            /* 连续错误请求最大数 */
            if (this.errorHttp >= 10) {
                this.finished = true;
                return false;
            }

            const params = {
                pageIndex: this.pageIndex + 1,
                pageSize: this.pageSize
            };

            getArticleApi(params).then(res => {
                const { code, data: { list = [], total = 0 } = {} } = res;
                /* 加载状态结束 */
                this.loading = false;

                if (code === 200) {
                    if (list.length) {
                        this.pageIndex = this.pageIndex + 1;
                        this.qqwMessages = [...this.qqwMessages, ...list];
                    }

                    if (this.qqwMessages.length >= total) {
                        this.finished = true;
                    }

                    this.errorHttp = 0;
                } else {
                    this.errorHttp += 1;
                }
            }).catch(err => {
                /* 加载状态结束 */
                this.loading = false;
                this.errorHttp += 1;
            });
        },
        goArticle(item) {
            if(item.relatedInformation.webUrl){
                window.location.href = item.relatedInformation.webUrl; 
            }else{
                this.$router.push(`/qqwDetail/${item.id}`)
            }
        },
    },
}