import request from "@/utils/request";

/* 预约回电 */
export const appointmentCallBack = (params) => {
  return request({
    url: "/node/appointment",
    params,
    method: "POST",
  });
};
