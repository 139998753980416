<template>
    <div class="qqwConsociation">
        <!-- 联盟合作 -->
        <!-- 第一屏 -->
        <div class="home_page_one">
            <div class="page_one_content">
                <img src="../../image/qqwConsociation/cooperation_banner@.png" alt="">
            </div>
        </div>
        <!-- 第二屏 -->
        <div class="home_page_two">
            <div class="page_two_content">
                <div class="page_twoTop">
                    <span>商超合作</span>
                </div>
                <div class="fu_title">全球蛙以“用数据赋能让全国超市·厂家商业决策更快、更简单、更智能”为使命，通过数字新技术、增量新商品、导购新运营三大赋能，为全国超市量身定制专属APP和小程序，让超市快速拥有数智能力；赋能超市导购拥有会员服务和商品营销能力，让导购实现收入倍增；联合百万供应商资源与超市共创增量商品，让超市实现利润增长！</div>
                <div class="fu_title">全球蛙立足山西，走向全国，截止当前业务覆盖四川、福建、广西、云南、湖北、湖南、浙江、山东等30个省级行政区，与福建冠超市、北京首航超市、张家口超市发超市、武汉中商超市等300多家品牌连锁企业达成战略合作，上线超市均通过全球蛙赋能实现了环比20%到50%的新增长！荣获国家商务部“数字商务企业”、科技部“高新技术企业”、工信部“国家级疫情防控重点保障企业”、中国连锁经营协会CCFA“年度最佳平台赋能零售创新奖”等多项荣誉。未来，全球蛙将携手万家超市百万导购为亿万用户提供高品质高性价比的商品和专业贴心的服务！
</div>
                <div class="page_twoImg">
                    <img src="../../image/qqwConsociation/cooperation_img_schz@.png" alt="">
                </div>
            </div>
        </div>
        <!-- 第三屏 -->
        <div class="home_page_three">
            <div class="page_three_content">
                <div class="page_threeTop">
                    <div>平台价值</div>
                </div>
               
                <van-grid :gutter="10" :column-num="2" :center='false'>
                    <van-grid-item v-for="(item, index) in page_three_cont" :key="index">
                        <div class="vanTop">
                            <img :src="item.vanTop" alt="">
                        </div>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
        <!-- 第四屏 -->
        <div class="home_page_four">
            <div class="page_four_content">
                <div class="page_fourTop">
                    <div>竞争优势</div>
                </div>
                <div v-for="(item, index) in page_four_cont" :key="index" class ='img_se'>
                        <div class="vanTop">
                            <img :src="item.vanTop" alt="">
                        </div>
                 </div>
            </div>
        </div>
        <!-- 第五屏 -->
        <div class="home_page_five">
            <div class="page_five_content">
                <div class="page_fiveTop">
                    <div>合作流程</div>
                </div >
                    <div v-for="(item, index) in page_five_cont" :key="index" class ='img_se'>
                        <div class="vanTop">
                            <img :src="item.vanTop" alt="">
                        </div>
                    </div>
            </div>
        </div>
        <!-- 第六屏 -->
        <div class="home_page_six">
            <div class="page_six_content">
                <div class="sixTop">用数据赋能让全国超市·厂家商业决策更快、更简单、更智能</div>
                <div class="sixCont">为您量身定制专属APP/小程序</div>
                <div class="sixBtn">
                     <a href="tel:15034044866">立即联系</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import qqwConsociationJS from '../qqwConsociation/qqwConsociation.js'
export default {
    mixins: [qqwConsociationJS]
}
</script>

<style lang="scss" scoped>
    @import '../qqwConsociation/qqwConsociation.scss'
</style>