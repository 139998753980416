export default {
    data() {
        return {
            //第二屏 数据
            activeName: 'a',
            page_two_cont: [
                {
                    vanTop:'服务半径受限',
                    vanCont: '客户流失？',
                    vanBott: '没有实现全渠道运营',
                    vanBotts: '新消费群体无法锁定',
                },
                {
                    vanTop:'线上经验缺乏',
                    vanCont: '运营难？',
                    vanBott: '运营人才培养成本大',
                    vanBotts: '专业缺乏转型见效慢',
                },
                {
                    vanTop:'存量新品老旧',
                    vanCont: '没新品？',
                    vanBott: '仓储运输成本太大',
                    vanBotts: '商品种类数量受限',
                },
                {
                    vanTop:'自主研发受阻',
                    vanCont: '成本高？',
                    vanBott: '第三方平台抽成高',
                    vanBotts: '自主研发投入成本大',
                }
            ],
            //第四屏 数据
            page_four_cont: [
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_xiaochengxu.png'),
                    vanCont: '门店拼团小程序',
                    vanBott: '定制功能更齐全',
                    vanBotts: '拼团营销更简单',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_tongcheng.png'),
                    vanCont: '同城到家APP',
                    vanBott: '全面升级人货场',
                    vanBotts: '用户留存更简单',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_shequpintuan.png'),
                    vanCont: '社区拼团小程序',
                    vanBott: '全渠道打通功能完备',
                    vanBotts: '百种营销裂变玩法',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_tuanzhang.png'),
                    vanCont: '团长数智化',
                    vanBott: '顾客专业分析',
                    vanBotts: '线上分销保障',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_shangjia@3x.png'),
                    vanCont: '门店数智化',
                    vanBott: '销售数据可视化',
                    vanBotts: '经营收益一目了然',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_baobiao@3x.png'),
                    vanCont: '管理数智化',
                    vanBott: '经营数据轻松整合',
                    vanBotts: '管理决策更加清晰',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_daogou@3x.png'),
                    vanCont: '导购数智化',
                    vanBott: '打破交互屏障',
                    vanBotts: '24小时贴心服务',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_jianpei@3x.png'),
                    vanCont: '商品数智化',
                    vanBott: '线上同步门店货',
                    vanBotts: '在线管理进销存',
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_pos@3x.png'),
                    vanCont: '交易数智化',
                    vanBott: '线上线下全全渠道',
                    vanBotts: '随时随地随心购',
                },
            ],
            //第五屏 数据
            page_five_conts: [
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_gongyinglian.png'),
                    vanCont: '增量新品',
                    vanBottOne: '反向嵌入/增量新品',
                    vanBottTwo: '工厂直达/一件代发',
                    vanBottThree: '比例分成/共享红利'
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_pinlei@3x.png'),
                    vanCont: '品类精选',
                    vanBottOne: 'O2O·门店货全品类',
                    vanBottTwo: 'C2M·国货土货洋货',
                    vanBottThree: 'O+O·同城异业联盟'
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_baokuan@3x.png'),
                    vanCont: '爆品打造',
                    vanBottOne: 'O2O·门店自提爆款',
                    vanBottTwo: 'O+O·同城异业爆款',
                    vanBottThree: 'C2M·全国包邮爆款'
                },
            ],
            //第六屏 数据
            page_six_conts: [
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_pintuanyingxiao@3x.png'),
                    vanCont: '拼团营销',
                    vanBottOne: '门店引流/导购分销',
                    vanBottTwo: '社群激活/会员裂变',
                    vanBottThree: '拉动新客/提高复购'
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_zhibo@3x.png'),
                    vanCont: '直播推广',
                    vanBottOne: '流程设计/选品标准',
                    vanBottTwo: '导播推流/音频标准',
                    vanBottThree: '直播打造/粉丝引流'
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_shequn@3x.png'),
                    vanCont: '社群营销',
                    vanBottOne: '社群运营/拉新派券',
                    vanBottTwo: '用户标签/精准触达',
                    vanBottThree: '社群效应/互动升级'
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_moban@3x.png'),
                    vanCont: '千套运营模板',
                    vanBottOne: '线下氛围/爆品海报',
                    vanBottTwo: '热点海报/节气海报',
                    vanBottThree: '商品文描/页面装修'
                },
                {
                    vanTop: require('../../image/qqwTradeScheme/programme_icon_yiduiyi@3x.png'),
                    vanCont: '一对一培训',
                    vanBottOne: '系统对接/产品部署',
                    vanBottTwo: '系统培训/商品选品',
                    vanBottThree: '拉新推广/项目复盘'
                }
            ]
        }
    },
    methods: {
        mounted() {
            this.$refs.checkbox
        },
    },
}