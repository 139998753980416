<template>
    <div class="qqwAboutMy qqwCartoon">
        <!-- 关于我们 -->
        <!-- 页面跟容器 放置背景图层 -->
      <div class="home_content" :style="height">
            <!-- 第一屏 背景+按钮 -->
            <div class="home_background">
                <!-- 中间图 按钮 -->
                <div class="home_down">
                    <div class="home_down_titles">
                    <img src="../../image/homeContainer/us_logo@.png" />
                    </div>
                </div>
                <!-- 动画 下滑按钮 -->
                <div class="home_down_animation">
                    <img src="../../image/homeContainer/icon_down@3x.png" />
                </div>
            </div>
            <!-- 第二屏 -->
            <div class="home_page_two">
                <div class="page_two_content">
                    <div class="page_twoTop">
                        <span>公司简介</span>
                    </div>
                    <div class="fu_title">山西全球蛙电子商务有限公司（以下简称“全球蛙”）创建于2015年9月，总部位于山西省太原市，现共有员工300余人。</div>
                    <div class="fu_title">公司以“用数据赋能让全国超市·厂家商业决策更快、更简单、更智能”为使命，通过前端分散后端聚合的方式，为全国超市分布式SaaS定制专属营销工具，并用数智化营销、数智化采购、数智化管理三大核心业务，发挥全球蛙平台经济效应，赋能生态伙伴实现增收、降本、提效，真正增强实体经济的核心竞争力，推进数字产业化和产业数字化，助推传统实体经济与数字经济的融合发展。</div>
                    <div class="fu_title">未来，全球蛙将继续秉持着成人达己的初心，携手万家超市百万导购为亿万用户提供高品质高性价比的全球好货和专业贴心的服务！ </div>
                    <div class="page_twoImg">
                        <!-- <img src="../../image/qqwConsociation/cooperation_img_schz@.png" alt=""> -->
                        <video class="gaunyu_jianjie_video" controls="" controlslist="nodownload" poster="../../image/qqwConsociation/001.jpg">
                            <source src="http://applet.quanqiuwa.com/qqw_gw/qqw_video.mp4" type="">
                        </video>
                    </div>
                </div>
            </div>
            <!-- 第三屏 -->
            <div class="home_page_three">
                <div class="page_three_content">
                    <div class="page_threeTop">
                        <div>公司文化</div>
                    </div>
                    <div class="vanTop_cont">
                        <div class="vanTop_contItem" v-for="(item, index) in page_three_conts" :key="index">
                            <div class="vanTop">
                                <img :src="item.vanTop" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 第四屏 -->
            <div class="home_page_four">
                <div class="page_four_content">
                    <div class="page_fourTop">
                        <div>发展历程</div>
                    </div>
                    <div class="hist-year">
                        <ul>
                            <li>
                                <p>2020年 &gt;&gt;</p>
                            </li>
                            <li class="cont_li">
                                <span class="month">11.30</span>
                                <span class="text">全球蛙作为山西唯一一家企业入选国家商务部第二批“数字商务企业”</span>
                            </li>
                            <li><span class="month">11.23</span>
                                <span class="text">全球蛙获评2020年度CCFA零售创新优秀赋能案例</span>
                            </li>
                            <li><span class="month">11.18</span>
                                <span class="text">全球蛙获评2020年山西省互联网20强</span>
                            </li>
                            <li> 
                                <p>2019年 &gt;&gt;</p>
                            </li>
                             <li class="cont_li">
                                <span class="month">05.25</span>
                                <span class="text">2019全球蛙入选山西省大数据领域重点推进项目</span>
                            </li>
                            <li><span class="month">05.07</span>
                                <span class="text">山西省委书记楼阳生考察全球蛙</span>
                            </li>
                            <li> 
                                <p>2018年 &gt;&gt;</p>
                            </li>
                             <li class="cont_li">
                                <span class="month">06.26</span>
                                <span class="text">全球蛙四川分公司落户成都</span>
                            </li>
                            <li><span class="month">03.12</span>
                                <span class="text">山西省副省长王一新一行到全球蛙调研考察</span>
                            </li>
                            <li> 
                                <p>2017年 &gt;&gt;</p>
                            </li>
                             <li class="cont_li">
                                <span class="month">01.19</span>
                                <span class="text">全球蛙商城APP正式上线</span>
                            </li>
                            <li> 
                                <p>2016年 &gt;&gt;</p>
                            </li>
                             <li class="cont_li">
                                <span class="month">04.19</span>
                                <span class="text">全球蛙获得4000万元人命币的天使投资</span>
                            </li>
                            <li> 
                                <p>2015年 &gt;&gt;</p>
                            </li>
                             <li class="cont_li">
                                <span class="month">09.08</span>
                                <span class="text">山西全球蛙电子商务有限公司成立</span>
                            </li>
                        </ul>
                        <div class="hist_fenge"></div>
                    </div>
                </div>
            </div>
            <!-- 第五屏 -->
            <div class="home_page_five">
                <div class="page_five_content">
                    <div class="page_fiveTop">
                        <div>公司荣誉</div>
                    </div>
                
                    <van-grid :gutter="10" :column-num="2" :center='false'>
                        <van-grid-item v-for="(item, index) in page_five_conts" :key="index">
                            <div class="vanTop">
                                <img :src="item.vanTop" alt="">
                            </div>
                        </van-grid-item>
                    </van-grid>
                </div>
            </div>
            <!-- 第六屏 -->
            <div class="home_page_fives">
                <div class="page_five_content">
                    <div class="page_five_logo">
                        <img src="../../image/logo/home_logo@3x.png" alt="">
                    </div>
                    <div class="page_five_www">www.quanqiuwa.com</div>
                    <div class="page_five_font"> <a href="tel:400-186-6558"> 400-186-6558</a></div>
                    <div class="page_five_site_box">
                        <div class="page_five_site">
                            <div class="companyName">山西公司：</div>
                            <div class="companyAddress">太原市小店区万立科技大厦8/9层</div>
                        </div>
                        <div class="page_five_site">
                            <div class="companyName">广东公司：</div>
                            <div class="companyAddress">深圳市宝安区西乡街道南昌社区新零售数字化产业园B栋310</div>
                        </div>
                        <div class="page_five_site">
                            <div class="companyName">福建公司：</div>
                            <div class="companyAddress">厦门市思明区观音山高雄路18号通达大厦20层2006</div>
                        </div>
                        <div class="page_five_site">
                            <div class="companyName">四川公司：</div>
                            <div class="companyAddress"> 成都市高新区天府大道北段1700号环球中心E2-1309（1-3-1309）</div>
                        </div>
                        <div class="page_five_site">
                            <div class="companyName">山东公司：</div>
                            <div class="companyAddress">青岛市市南区香港中路远洋大厦B座35层</div>
                        </div>
                    </div>
                    <div class="page_five_records">
                        <span>Copyright © 2013 - 2020 Tencent Cloud.</span>
                        <span>All Rights Reserved. 全球蛙 版权所有</span>
                        <span>
                            <img src="../../image/homeContainer/home_icon_police_logo@3x.png" alt="">
                            晋公网安备 11010802017518 晋B2-20090059-1
                        </span>
                    </div>
                </div>
            </div>
      </div>
    </div>

</template>

<script>
import { ClientHeights } from '../common/common.js'
import qqwAboutMyJS from '../qqwAboutMy/qqwAboutMy.js'
export default {
    mixins:[qqwAboutMyJS],
     data(){
        return {
            height: {
                height: ClientHeights(),
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../common/common.scss';
    @import '../qqwAboutMy/qqwAboutMy.scss';
</style>