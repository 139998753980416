<template>
  <!-- 页面跟容器 放置背景图层 -->
  <div class="home_content">
    <!-- 第一屏 主图+按钮 -->
    <div class="home_background">
      <img
        class="main-image"
        src="@/image/homeContainer/home_bg@3x.png"
        alt=""
      />
      <!-- 动画 下滑按钮 -->
      <div class="home_down_animation">
        <img src="@/image/homeContainer/icon_down@3x.png" />
      </div>
    </div>
    <!-- 第二屏  方案-->
    <div class="home_page_one">
      <div class="title">全球蛙助您生意增长</div>
      <div class="content">
        全球蛙作为零售企业的数智化转型服务商，为不同区域、不同规模的 “
        零售商·品牌商 ”
        提供不同的全域全渠道数智化产品技术解决方案，为企业提供强有力的组织数智化与业务数智化的转型支撑，从而助力企业实现效率与效益双提升！
      </div>
      <div
        v-for="pro in programme"
        :key="pro.path"
        class="programme-item"
        @click="$router.push(pro.path)"
      >
        <img class="programme-item-image" :src="pro.src" alt="" />
        <div class="programme-item-title">{{ pro.title }}</div>
        <div class="programme-item-content">{{ pro.content }}</div>
        <div class="programme-item-detail">
          <span>查看详情</span>
          <img src="@/image/homeContainer/btn_more_home@3x.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 第三屏  零售伙伴 + 品牌 -->
    <div class="home_page_two">
      <div v-for="caseItem in cases" :key="caseItem.id" class="cases-container">
        <div class="cases-tips">
          <img :src="caseItem.src" alt="" />
          <span>{{ caseItem.tips }}</span>
        </div>
        <div class="cases-title">{{ caseItem.title }}</div>
        <div class="cases-inon-container">
          <div class="cases-inon-container-box">
            <div
              class="cases-inon-container-item"
              v-for="(icons, iconsIndex) in caseItem.brandIcon"
              :key="`icons${iconsIndex}`"
            >
              <img
                :src="icon"
                v-for="(icon, iconIndex) in icons"
                :key="`icon${iconIndex}`"
              />
            </div>
          </div>
        </div>
        <div class="swiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(brandItem, brandIndex) in caseItem.brand"
              :key="`brand${brandIndex}`"
            >
              <img :src="brandItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <contact-us />
    <action-sheet :menu="enumerableRoutes" />
  </div>
</template>

<script>
import homeContainerJS from "./index.js";
export default homeContainerJS;
</script>

<style lang="scss">
@import "@/components/common/common.scss";
@import "./index.scss";
.content {
  color: $textColor999 !important;
  font-size: 26px;
}
.cases-container:nth-of-type(2) {
  margin-top: 60px;
}
</style>
