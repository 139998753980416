<template>
  <van-popup
    round
    v-model="visible"
    position="right"
    :overlay="false"
    :style="{ height: '100%' }"
  >
    <ul class="home_nav_ul">
      <li
        v-for="route in routes"
        :key="route.path"
        @click="handleRouter(route.path)"
      >
        <div class="home_nav_ul_a">
          {{ route.title }}
        </div>
      </li>
    </ul>
  </van-popup>
</template>

<script>
import routes from "@/router/menu";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    handlePopup: {
      type: Function,
    },
  },
  data: () => ({
    routes,
  }),
  methods: {
    handleRouter(path) {
      if (this.$route.path != path) {
        this.$router.push(path);
      }
      this.handlePopup();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variable.global.scss";
.van-popup--right.van-popup--round {
  width: 100%;
  border-radius: 0;
  margin-top: 88px;
  .home_nav_ul {
    margin: 120px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: $bgColorfff;
    li {
      margin: 0 auto;
      margin-top: 60px;
      font-size: 32px;
      .home_nav_ul_a {
        font-family: PingFang SC-Medium;
        font-size: 32px;
        color: $textColor333;
      }
    }
  }
}
</style>
