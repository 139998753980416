import { getHomeListApi } from '@/apis/home';

export default {
    // name: 'homeContainer',
    data() {
        return {
            // 轮播 自定义
            current: 0,
            indicator: '#5cb531',
            showfixd_bottom: false,
            // 全球蛙经典客户案例 - 模拟数据
            qqwClient: [{
                    title: '山西美特好超市',
                    appType: '小程序/APP',
                    appImg: require('../../image/homeContainer/home_kehu_pic.png'),
                    appDistrict: '山西·太原',
                    appcont: '在数智化转型升级的路上，全球蛙与美特好一路相伴，助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线！打造独立专属的“美特好优鲜到家”APP，实现线上线下全渠道自主运营。疫情期间线上订单激增5倍，全面服务420万太原市民。在2020年618拼团活动中，创下单日拼团活动销售突破200万的骄人战绩！'
                },
                {
                    title: '山西美特好超市',
                    appType: '小程序/APP',
                    appImg: 'https://img.yzcdn.cn/vant/apple-1.jpg',
                    appDistrict: '山西·太原',
                    appcont: '在数智化转型升级的路上，全球蛙与美特好一路相伴，助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线！打造独立专属的“美特好优鲜到家”APP，实现线上线下全渠道自主运营。疫情期间线上订单激增5倍，全面服务420万太原市民。在2020年618拼团活动中，创下单日拼团活动销售突破200万的骄人战绩！'
                },
                {
                    title: '山西美特好超市',
                    appType: '小程序/APP',
                    appImg: 'https://img.yzcdn.cn/vant/apple-2.jpg',
                    appDistrict: '山西·太原',
                    appcont: '在数智化转型升级的路上，全球蛙与美特好一路相伴，助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线！打造独立专属的“美特好优鲜到家”APP，实现线上线下全渠道自主运营。疫情期间线上订单激增5倍，全面服务420万太原市民。在2020年618拼团活动中，创下单日拼团活动销售突破200万的骄人战绩！'
                },
                {
                    title: '山西美特好超市',
                    appType: '小程序/APP',
                    appImg: 'https://img.yzcdn.cn/vant/apple-1.jpg',
                    appDistrict: '山西·太原',
                    appcont: '在数智化转型升级的路上，全球蛙与美特好一路相伴，助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线！打造独立专属的“美特好优鲜到家”APP，实现线上线下全渠道自主运营。疫情期间线上订单激增5倍，全面服务420万太原市民。在2020年618拼团活动中，创下单日拼团活动销售突破200万的骄人战绩！'
                },
                {
                    title: '山西美特好超市',
                    appType: '小程序/APP',
                    appImg: 'https://img.yzcdn.cn/vant/apple-1.jpg',
                    appDistrict: '山西·太原',
                    appcont: '在数智化转型升级的路上，全球蛙与美特好一路相伴，助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线！打造独立专属的“美特好优鲜到家”APP，实现线上线下全渠道自主运营。疫情期间线上订单激增5倍，全面服务420万太原市民。在2020年618拼团活动中，创下单日拼团活动销售突破200万的骄人战绩！'
                },
                {
                    title: '山西美特好超市',
                    appType: '小程序/APP',
                    appImg: 'https://img.yzcdn.cn/vant/apple-2.jpg',
                    appDistrict: '山西·太原',
                    appcont: '在数智化转型升级的路上，全球蛙与美特好一路相伴，助力美特好实现200多家门店在线、4万种商品在线、1万多名导购在线、160万会员在线！打造独立专属的“美特好优鲜到家”APP，实现线上线下全渠道自主运营。疫情期间线上订单激增5倍，全面服务420万太原市民。在2020年618拼团活动中，创下单日拼团活动销售突破200万的骄人战绩！'
                }
            ],
            // 全球蛙合作商户 - 模拟数据
            qqwCooperation: [
                // [
                //     'https://www.quanqiuwa.com/fragment/shanghu_1.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_2.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_3.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_4.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_5.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_6.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_7.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_8.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_9.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_10.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_11.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_12.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_13.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_14.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_15.png',
                // ],
                // [
                //     'https://www.quanqiuwa.com/fragment/shanghu_16.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_17.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_18.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_19.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_20.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_21.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_22.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_23.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_24.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_25.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_26.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_27.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_28.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_29.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_30.png',
                // ],
                // [
                //     'https://www.quanqiuwa.com/fragment/shanghu_31.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_32.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_33.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_34.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_35.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_36.png',
                //     'https://www.quanqiuwa.com/fragment/shanghu_37.png',
                // ]
            ]
        }
    },
    created() {
        this.onStoresList()

    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        document.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        // 获取轮播下标
        onChange(index) {
            this.current = index;
        },
        // 获取轮播数据 --测试
        onStoresList() {
            getHomeListApi().then(res => {
                const { code, data: { ClassicCustomerCases = [], MerchantLogo = [] } = {} } = res;

                if (code === 200) {
                    let offset = 15;
                    let qqwCooperation = [];
                    let qqwCooperationTotal = MerchantLogo.length;
                    let qqwCooperationNum = Math.ceil(qqwCooperationTotal / offset);

                    for (let i = 0; i < qqwCooperationNum; i++) {
                        let startIndex = i * offset;
                        let lastIndex = (i + 1) * offset;

                        lastIndex = lastIndex > qqwCooperationTotal ? qqwCooperationTotal : lastIndex;
                        qqwCooperation.push(MerchantLogo.slice(startIndex, lastIndex));
                    }

                    this.qqwClient = ClassicCustomerCases;
                    this.qqwCooperation = qqwCooperation;
                }
            });
        },
        handleScroll() { //获取滚动时的高度
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // let oneHeight = this.$refs.scrollOne.offsetHeight;
            // let twoHeight = this.$refs.scrollTwo.offsetHeight + oneHeight;
            // let threeHeight = this.$refs.scrollThree.offsetHeight + twoHeight;
            // 
            // console.log(scrollTop)
            if (scrollTop > 700) {
                this.showfixd_bottom = true
                    //滚动大于0的时候要做的操作
            } else {
                this.showfixd_bottom = false
            }


        }
    }
}