<template>
  <div class="app">
    <!-- header -->
    <basic-header />
    <!-- router-view -->
    <router-view />
    <!-- copy-right & portal website -->
    <copy-right />
    <!-- contact -->
    <contact-icon />
  </div>
</template>

<script>
import BasicHeader from "@/layouts/basic-header/index.vue";
import CopyRight from "@/layouts/copy-right/index.vue";
import ContactIcon from "@/layouts/contact-icon/index.vue";
import RouterView from "@/components/router-view/index.vue";
export default {
  components: {
    CopyRight,
    BasicHeader,
    ContactIcon,
    RouterView,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variable.global.scss";
html,
body,
.app {
  width: 100vw;
  min-height: 100vh;
  background-color: $bgColorfa;
}
// 根组件 基本样式
.app {
  padding: 88px 0 36px 0;
  min-width: 320px;
}
// 顶部 navbar
.van-overlay {
  background: none !important;
}
*::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
}
</style>
