import VueRouter from "vue-router";
import { routes } from "@/router/routes";
// 首页
import homeContainer from "./components/homeContainer/homeContainer.vue";
// qqwTradeScheme 行业解决方案
import qqwTradeScheme from "./components/qqwTradeScheme/qqwTradeScheme.vue";
// qqwConsociation 联盟合作
import qqwConsociation from "./components/qqwConsociation/qqwConsociation.vue";
// qqwClient 客户案例
import qqwClient from "./components/qqwClient/qqwClient.vue";
// qqwMessage 全球蛙资讯
import qqwMessage from "./components/qqwMessage/qqwMessage.vue";
// qqwAboutMy 关于我们
import qqwAboutMy from "./components/qqwAboutMy/qqwAboutMy.vue";
// qqwAboutMy 详情
import qqwDetail from "./components/qqwDetail/qqwDetail.vue";
// qqwRecruitment 人才招聘
import qqwRecruitment from "./components/qqwRecruitment/qqwRecruitment.vue";
// 3. 创建路由对象
var router = new VueRouter({
  routes: [
    /* index 重定向 */
    { path: "/", redirect: "/index" },

    /* 旧版 */
    { path: "/home", component: homeContainer },
    { path: "/qqwTradeScheme", component: qqwTradeScheme },
    { path: "/qqwConsociation", component: qqwConsociation },
    { path: "/qqwClient", component: qqwClient },
    { path: "/qqwMessage", component: qqwMessage },
    { path: "/qqwAboutMy", component: qqwAboutMy },
    { path: "/qqwDetail/:id", component: qqwDetail },
    { path: "/qqwRecruitment", component: qqwRecruitment },

    /* 新版 */
    ...routes,
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// 把路由对象暴露出去
export default router;
